import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const menuOpen = document.querySelector(".nav__menu__open");
const menuClose = document.querySelector(".menu__popup__close");

function openMenu(e) {
  const menu = document.querySelector(".menu__popup");
  gsap.from(".menu__popup", { yPercent: -100 });
  menu.classList.remove("hidden");
}

function closeMenu(e) {
  const menu = document.querySelector(".menu__popup");
  menu.classList.add("hidden");
}

function openMenuPanel() {
  menuOpen.addEventListener("click", (e) => {
    openMenu(e);
  });
}
function closeMenuPanel() {
  menuClose.addEventListener("click", (e) => {
    closeMenu(e);
  });
}

export { openMenuPanel, closeMenuPanel };
