import { openMenuPanel, closeMenuPanel } from "./menu";
import Footer from "./footer";
import Preloader from "./preloader";
const heroVideo = document.querySelector(".hero__video");
const buttonMute = document.querySelector(".hero__button--unmute");

//Function to toggle mute
buttonMute.addEventListener("click", (e) => {
  e.preventDefault();
  heroVideo.muted = !heroVideo.muted;
  buttonMute.textContent = heroVideo.muted ? "UNMUTE" : "MUTE";
});

//Function to open/close menu
openMenuPanel();
closeMenuPanel();

//Function to start Preloader
const preloader = new Preloader();
// preloader.startPreloader();
preloader.animation();

const footer = new Footer(".footer__links");
footer.addListeners();
