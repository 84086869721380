import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Footer {
  constructor(element) {
    this.element = document.querySelectorAll(element);
    this.background = document.querySelector(".footer");
    this.colors = [
      "#fa7e1e",
      "#3455F7",
      "#DBF227",
      "#D92525",
      "#00ABBD",
      "#FF9933",
      "#EF6024",
    ];
  }

  addListeners() {
    this.element.forEach((link, i) => {
      const colorIndex = i;
      link.addEventListener("mouseenter", (e) => {
        this.background.style.backgroundColor = this.colors[colorIndex];
        link.style.color = "#f2f2f2";
      });

      link.addEventListener("mouseleave", (e) => {
        this.background.style.backgroundColor = "#f2f2f2";
        link.style.color = "#0d0c0c";
      });
    });
  }
}
